import { Injectable, signal } from '@angular/core';
import { CourseI } from '../courses/interfaces/course.interface';
import { FilterCoursesI } from '../courses/interfaces/filter-courses.interface';
import { FilterInstructorsI } from '../instructors/interfaces/filter-instructors.interface';
import { InstructorI } from '../instructors/interfaces/instructor.interface';
import { FilterProgramsI } from '../programs/interfaces/filter-programs.interface';
import { ProgramI } from '../programs/interfaces/program.interface';
import { FilterStudentsI } from '../students/interfaces/filter-students.interface';
import { StudentI } from '../students/interfaces/student.interface';
import { DepartmentI } from './interfaces/department.interface';
import { FilterDepartmentsI } from './interfaces/filter-departments.interface';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  constructor() {}

  selectedDepartment = signal<DepartmentI | null>(null);

  filterDepartments = signal<FilterDepartmentsI | null>(null);

  editDepartment = signal<DepartmentI | null>(null);

  addDepartment = signal<DepartmentI | null>(null);

  deleteDepartment = signal<DepartmentI | null>(null);

  // ******************************************** ||

  filterPrograms = signal<FilterProgramsI | null>(null);

  editProgram = signal<ProgramI | null>(null);

  addProgram = signal<ProgramI | null>(null);

  // ******************************************** ||

  filterCourses = signal<FilterCoursesI | null>(null);

  editCourse = signal<CourseI | null>(null);

  addCourse = signal<CourseI | null>(null);

  // ******************************************** ||

  filterInstructors = signal<FilterInstructorsI | null>(null);

  editInstructor = signal<InstructorI | null>(null);

  addInstructor = signal<InstructorI | null>(null);

  // ******************************************** ||

  filterStudents = signal<FilterStudentsI | null>(null);

  editStudent = signal<StudentI | null>(null);

  addStudent = signal<StudentI | null>(null);
}
